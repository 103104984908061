import React from 'react';
import Navbar from 'components/navbar.js';
import Footer from 'components/footer.js';
import {theme} from './configs/theme.js';
import { ThemeProvider}  from '@mui/material/styles';

import 'css/app.css';

export default function App() {
  return (
    <ThemeProvider theme={theme}>
      <div id='root'>
        <Navbar/>
        <div className='under-nav'>
        home page
        </div>
        <Footer/>
      </div>
    </ThemeProvider>
  );
}

