import React  from 'react';

import 'css/navbar.css';

export default function Navbar() {
  // console.log(showNav);
  return (
    <div id='navbar'>
      <div className='logo-box'>
        텍스트럼
      </div>
    </div>
  );

}