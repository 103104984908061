import React, {useState} from 'react';
import {Button, Dialog, DialogActions, DialogContent,
  DialogContentText, DialogTitle} from '@mui/material';
// import ServiceTerms from 'Components/ServiceTerms.js';

import 'css/footer.css';

export default function Footer() {
  const [openTerms, setOpenTerms] = useState(false);

  return (
    <div id='footer'>
      <div className='top-line'>
        <div className='logo-box'>
          텍스트럼
        </div>
        <div className='expanded'/>
        <div className='title'>
          한 글자 한 글자의 스펙트럼, 텍스트럼
        </div>
        <div style={{width: '50px'}}/>
      </div>

      <hr/>

      <div className='content-box'>
        Co. 텍스트럼<br/>
        사업자 번호: 315-08-81046<br/>
        대표자: 김현우<br/>
        소재지: 서울특별시 서대문구 신촌로7길 45(창천동) <br/>
        TEL: 010-8637-4010 <br/>
        email: support@textrum.co.kr <br/><br/><br/>
      </div>

      <div className='bottom-line'>
        <div className='policy-align'>
          {/* Terms of Service */}
          <a className='policy' onClick={() => setOpenTerms(true)}>이용약관</a>
          {/* Privacy Policy */}
          <a className='policy'>개인정보방침</a>
        </div>

      </div>

      <Dialog
        open={openTerms}
        onClose={() => setOpenTerms(false)}
      >
        <DialogTitle>
          {'약관 체결의 성립 동의'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            sample service and terms
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={() => setOpenTerms(false)}>확인</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
